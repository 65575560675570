.header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  -webkit-transition: 300ms all;
  transition: 300ms all;
  background-color: #6ab04c;
  height: 5rem;
  z-index: 5;
  top: 1rem;
  margin: 0 5.5vw;
  width: 89vw;
  .header--inner {
    margin: auto auto !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    height: auto;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-left: 3vw;

    .header--img {
      width: 12rem;
      height: auto;
    }
  }
}
.header--breadcrumbs {
  margin-left: 5.5vw;
}
.header--shut {
  width: 100% !important;
  margin: 0 0 !important;
  position: fixed;
  top: 0 !important;
  -webkit-transition: 0.5s all;
  transition: 0.5s all;
}
.header--nav {
  .breadcrumbs {
    padding-left: 0 !important;
  }
  margin-top: 3.2rem;
  padding-top: 4rem;
  background-color: #f6f6f6;
  padding-bottom: 2rem;
  .label {
    margin-left: 5.5vw !important;
    font-size: 1.2rem;
    font-weight: 700;
  }
}
@media (max-width: 768px) {
  .header {
    height: 4.5rem;

    .header--inner {
      .header--img {
        width: 10rem;
      }
    }
  }
  .header--nav {
    padding-top: 4rem;
  }
  .svg {
    top: 0.2rem !important;
    right: -28vw !important;
  }
}
@media (max-width: 560px) {
  .header {
    margin: 0 !important;
    width: 100vw;
    height: 4rem;
    .header--inner {
      .header--img {
        width: 8rem;
      }
    }
    top: 0 !important;
  }
  .header--nav {
    padding-left: 4vw;
    padding-bottom: 1rem;
    padding-top: 5rem;
    margin-top: 0 !important;
    .breadcrumbs {
      padding-left: 0 !important;
    }
    .label {
      margin-left: 2vw !important;
    }
    .header--breadcrumbs {
      margin-left: 0 !important;
      padding-left: 2vw !important;
    }
  }
  .svg {
    width: 1.5rem !important;
  }
}

@media (max-width: 360px) {
  .svg {
    right: -24vw !important;
    top: 0.1rem !important;
  }
}
.svg {
  width: 2rem;
  display: block;
  position: absolute;
  right: -31vw;
  top: 0.4rem;
  fill: #fff;
}
