.massage {
  margin-top: 1rem;
  .massage--date {
    -ms-grid-column-align: start;
        justify-items: start;
  }
}
.massage__type--inner {
  padding: 1.5rem 0;
}
.massage__type--inner:nth-last-child(n + 3) {
  border-bottom: #91cc78 1px solid;
}
.massage__type {
  margin: 0.5rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  cursor: pointer;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;

  .massage__type--title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 5px;
    position: relative;
    padding-right: 1rem;
  }
  .massage__type--seans {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
  }
}
.massage__type--alert:hover::before {
  content: attr(data-title); /* Выводим текст */
  cursor: pointer;
  font-weight: 400;
  position: absolute; /* Абсолютное позиционирование */
  // left: 20%;
  top: 100%; /* Положение подсказки */
  right: 0%;
  color: #fff;
  z-index: 100; /* Отображаем подсказку поверх других элементов */
  background: #6ab04c; /* Полупрозрачный цвет фона */
  font-size: 0.9rem; /* Размер текста подсказки */
  padding: 1rem 1.5rem; /* Поля */
  border-radius: 10px;
  border: 1px solid #6ab04c; /* Параметры рамки */
}

@media (max-width: 768px) {
  .massage__type--alert:hover::before {
    right: 0%;
  }
}
