.bx24_form {
  .close--bathhouse {
    -webkit-animation: closeCheackBox ease-out 1s;
            animation: closeCheackBox ease-out 1s;
    -webkit-transition: all 1s;
    transition: all 1s;
  }
  .bathhouse_sauna, .massage {
    display: block;
    position: relative;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    // width: max-content;
    &.bathhouse--date {
      display: -ms-grid;
      display: grid;
      position: relative;
      -ms-grid-columns: minmax(200px, 1fr) minmax(200px, 1fr);
          grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr);
    }
    .bathhouse--date--day,
    .bathhouse--date--time {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      gap: 10px;
      -webkit-box-align: baseline;
          -ms-flex-align: baseline;
              align-items: baseline;
    }
    .checkbox--time{
    -ms-grid-columns: minmax(200px, 1fr) minmax(200px, 1fr);
        grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr);
    -ms-grid-column-align: start;
        justify-items: start;
    }
  }
  .bathhouse_sauna--active {
    padding: 1rem 0;
    // border-bottom: 1px solid #6ab04c;
  }
  .bathhouse--drinkAndTreats {
    margin-top: 1rem;
  }
}

@media (max-width: 616px) {
  .bathhouse--date {
    -ms-grid-columns: 1fr !important;
        grid-template-columns: 1fr !important;
  }
  .bathhouse--date--day,
  .bathhouse--date--time {
    width: 250px !important;
    // width: minmax(300px, 1fr) !important;
    // justify-content: space-between;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: baseline;
        -ms-flex-align: baseline;
            align-items: baseline;
  }
  .bathhouse_time {
    width: 79%;
    padding-right: 10px;
    margin-right: 1rem !important;
  }
  .item__add {
    left: 7rem;
  }
  .item__remove {
    left: 5.7rem;
  }
}

	