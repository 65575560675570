.modal--wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  height: 100%;
  width: 100%;
  top: calc(50vh - 150px);
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
  z-index: 999;
  .modal--inner {
    display: -ms-grid;
    display: grid;
    position: relative;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    margin: 0 2rem;
    color: #fff;
    background-color: #6ab04c;
    border-radius: 10px;
    padding: 1rem;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    -ms-flex-line-pack: center;
    align-content: center;
    -ms-grid-column-align: center;
        justify-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    .modal--header {
      padding: 1rem 0;
      text-align: justify;
    }
    .modal--bottom {
      .modal--buttom {
        cursor: pointer !important;
        color: #ffffff !important;
        font-size: 0.8rem;
        font-weight: 700;
        text-transform: uppercase;
        border: 1px solid rgb(255 255 255 / 40%) !important;
        border-radius: 100px;
        padding: 12px 27px;
      }
    }
  }
}
.modal--inner--wrapper {
  top: 0;
  left: 0;
  display: block;
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgb(165, 154, 154);
  opacity: 0.6;
  z-index: 998;
}
@media (min-width: 769px) {
  .modal--inner {
    width: 60vw !important;
  }
}
