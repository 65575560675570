.broom {
  margin: 1rem 0 0;
  display: -ms-grid;
  display: grid;
  position: relative;
  gap: 1rem;
    .close-broom{
        -webkit-animation: closeCheackBox ease-out 1s;
                animation: closeCheackBox ease-out 1s;
        // transition: all 1s;
    }
  -webkit-transition: all 1s;
  transition: all 1s;
  .broom--ordinary,
  .broom--aromatic {
    margin: 0 0 1rem 30px;
  }
  .input {
    margin-top: 0 !important;
  }
}

@media (max-width: 768px) {
  .broom {
    .extraSpace_range {
      width: 40px !important;
      padding-right: 2px !important;
    }
  }
}
