.alert {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;

  .alert-show {
    font-family: inherit;
    height: auto;
    width: 70vw;
    max-width: 25rem;
    padding: 2.5rem 1rem 2rem 3rem;
    z-index: 100;
    position: fixed;
    opacity: 0.9;
    color: #fff;
    font-size: 0.9rem;
    font-weight: 600;
    background-color: #6ab04c;
    -webkit-animation: show_on 3000ms ease-in-out;
            animation: show_on 3000ms ease-in-out;
    -webkit-animation: top 500ms ease-in-out;
            animation: top 500ms ease-in-out;
    border: #fff 1px solid !important;
  }

  @-webkit-keyframes show_on {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.9;
    }
    90% {
      opacity: 0.9;
    }
    100% {
      opacity: 0 !important;
    }
  }

  @keyframes show_on {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 0.9;
    }
    90% {
      opacity: 0.9;
    }
    100% {
      opacity: 0 !important;
    }
  }
  @-webkit-keyframes top {
    0% {
      top: 6rem;
    }
  }
  @keyframes top {
    0% {
      top: 6rem;
    }
  }
}
.alert--question {
  display: block;
  border-radius: 50%;
  border: 2.5px solid #6ab04c;
  padding: 1px 4px;
  font-size: 12px;
  font-weight: 700;
}
.alert--checkbox {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 10px;
}
@media (max-width: 426px){
    .alert--pet{
        width: -webkit-min-content !important;
        width: -moz-min-content !important;
        width: min-content !important;
    }
}
