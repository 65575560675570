.banquet--person {
  display: -ms-grid !important;
  display: grid !important;
  -ms-grid-columns: minmax(200px, 1fr) minmax(200px, 1fr) !important;
  grid-template-columns: minmax(200px, 1fr) minmax(200px, 1fr) !important;
}
.banquet--grid {
  justify-items: start !important;
  .item__add {
    left: 5.3rem;
  }
  .item__remove {
    left: 4rem;
  }
  .checkbox--time {
    -ms-grid-columns: auto 1fr;
    grid-template-columns: auto 1fr;
    .banquet--input{
        width: 88%;
    }
  }
}
.banquet--data {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: -webkit-max-content 10px 1fr;
  -ms-grid-columns: max-content 10px 1fr;
  grid-template-columns: -webkit-max-content 1fr;
  grid-template-columns: max-content 1fr;
  grid-gap: 10px;
  gap: 10px;
  -webkit-box-align: baseline;
      -ms-flex-align: baseline;
          align-items: baseline;
}
@media (max-width: 850px) {
  .banquet--grid {
    -ms-grid-columns: minmax(200px, 1fr) !important;
    grid-template-columns: minmax(200px, 1fr) !important;
  }
}
@media (max-width: 450px) {
  .banquet--textarea {
    width: 88% !important;
  }
  .banquet--person {
    -ms-grid-columns: minmax(170px, 1fr) minmax(70px, 1fr) !important;
    grid-template-columns: minmax(170px, 1fr) minmax(70px, 1fr) !important;
  }
  .banquet--grid {
    .item__add {
      left: 4rem;
    }
    .item__remove {
      left: 2.7rem;
    }
    .extraSpace_range {
      width: 40%;
    }
  }
}