.greeting--form {
  position: relative;
  // text-align: justify;
  display: block;
}
.end--active {
  opacity: 1;
  -webkit-animation: closeForm ease-out 0.7s !important;
          animation: closeForm ease-out 0.7s !important;
}
.form--active {
  -webkit-animation: openForm ease-out 0.7s !important;
          animation: openForm ease-out 0.7s !important;
}
.greeting--house,
.greeting--time {
  display: block;
  margin-top: 1.2rem;
  span {
    padding: 0.3rem 0;
    display: block;
    font-weight: 700;
    color: #6ab04c;
  }
}
.greeting--name {
  font-weight: 700;
  color: #6ab04c;
}
@media (max-width: 768px) {
  .greeting--form {
    font-size: 1rem !important;
  }
}
@keyframes closeForm {
  from {
    // height: 2%;
    opacity: 1;
  }
  to {
    // height: max-content;
    opacity: 0.2;
  }
}
@keyframes openForm {
  from {
    // height: 2%;
    opacity: 0.2;
  }
  to {
    // height: max-content;
    opacity: 1;
  }
}
