.pavilion--checkbox{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}
.pavilion--label{
    margin: 1.5rem 0 0.5rem;
}
.pestwell-btn{
display: flex;
position: relative;
justify-content: center;
}