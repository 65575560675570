.accommodation--form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: block;
  position: relative;
  margin: 0.5rem 0;
}
.bed {
  display: -webkit-box;
  display: -ms-flexbox;
  display: block;
  position: relative;
  margin: 0.5rem 0;
}
.pet {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  margin: 0.5rem 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
